'use client'

import React from 'react'
import type { TypographyProps } from '@mui/material/Typography'
import Typography from '@mui/material/Typography'
import LogoText from '@UI/sharedComponents/LogoText'

export type TextWithLogoReplacedProps = {
  text: string
} & TypographyProps

function TextWithLogoReplaced({
  text,
  ...props
}: TextWithLogoReplacedProps): React.ReactElement {
  const regex = /liv/gi
  const matches = text.match(regex)

  if (!matches) {
    return <Typography {...props}>{text}</Typography>
  }

  const parts = text.split(regex)
  const elements = []

  for (let i = 0; i < parts.length; i++) {
    elements.push(parts[i])

    if (i < parts.length - 1) {
      elements.push(
        <LogoText {...props} key={`logo-${String(i)}`} component="span" />,
      )
    }
  }

  return <Typography {...props}>{elements}</Typography>
}
export default TextWithLogoReplaced
